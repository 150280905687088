<template>
  <section class="programari">
    <main-modal />

    <pacient-header />

    <ul class="lista-history" data-test="lista-tratamente">
      <li
        class="relative"
        :class="{ pointer: item.send_same_day_sms }"
        @click="edit(item)"
        v-for="item in programari"
        :key="item.id"
      >
        <div class="flex flex-between h100">
          <div>
            <h4>
              {{ item.medic }}
              <span
                >{{ checkObjProps(item, "deLa.ora") }}:{{
                  checkObjProps(item, "deLa.minute")
                }}
                - {{ checkObjProps(item, "panaLa.ora") }}:{{
                  checkObjProps(item, "panaLa.minute")
                }}</span
              >
            </h4>
            <span>{{ moment(item.createdAt).format("DD MMM YYYY") }}</span>
          </div>
        </div>
      </li>
    </ul>

    <button
      @click="addProgramare"
      data-test="creaza-programare"
      class="bg-transparent flex-auto noborder flex m-left-auto pointer"
    >
      <i v-html="svg.addPacient"></i>
    </button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import { checkObjProps } from '../store/utils';
  export default {
    name: 'programari',

    data () {
      return {
        moment,
        checkObjProps
      };
    },

    computed: {
      ...mapState({
        svg: 'svg',
        pacient: 'pacient',
        programari: 'programari'
      })
    },

    methods: {
      addProgramare () {
        this.$store.commit('SET_MODAL', {
          name: 'addProgramare',
          val: true
        });
      },

      edit (value) {
        if (value.send_same_day_sms) {
          const obj = {
            name: 'addProgramare',
            val: true
          };
          this.$store.commit('SET_MODAL', obj);
          this.$store.commit('SET_KEY_VALUE', {
            key: 'modalProgramare',
            value
          });
        }
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .programari {
    padding-top: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    svg #Oval {
      transition: fill 0.2s ease-in-out;
    }

    div.columns {
      //border: 1px solid red;
    }

    ul.lista-history {
    }

    button.noborder {
      margin-top: auto;
    }

    button.noborder:hover {
      svg #Oval {
        fill: darken(@main-green, 20%, relative);
      }
    }
  }
</style>
